<template>
  <div class="">
    <HeaderVue :heading="'Lists & Segments > '+ segmentName" back="/manage/emails/segments" class="pb-3">
      <template v-slot:buttons>
        <div  class="d-flex align-items-center">
          <div class="download-title mr-4">Download .XLS</div>
          <div class="">
            <button @click="exportToExcel()" class="btn p-2 download-btn">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_35_1729)">
                  <path
                    d="M19 9.5H15V3.5H9V9.5H5L12 16.5L19 9.5ZM5 18.5V20.5H19V18.5H5Z"
                    fill="#4D1B7E" />
                </g>
                <defs>
                  <clipPath id="clip0_35_1729">
                    <rect y="0.5" width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      </template>
    </HeaderVue>
    <div class="card">
      <div class="card-body">
        <div class="">
          <b-input-group class="mt-3">
            <div class="d-flex align-items-center py-2 w-100 search pl-2">
              <i class="fas fa-search icon px-2"></i>
              <input
                type="text"
                v-model="title"
                placeholder="Search here"
                id=""
                class="w-100  search-text"
                style="border: none" />
             
            </div>
            <!-- <div class="d-flex py-2 align-items-center tags">
      <div class="tag py-1 px-2 mx-2 d-flex justify-content-between align-items-center" v-if="filter.title">
        <span class="pr-1">Search: {{filter.title}}</span>
        <i class="fa fa-close cursor-pointer" aria-hidden="true" @click="filter.title=''"></i>
      </div>
    </div> -->
           
          </b-input-group>
          <div class="loader d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center" v-if="isLoading">
              <b-spinner small style="color: #4d1b7e"></b-spinner>
              <span class="ml-2">Loading Leads</span>
            </div>
          </div>
          <!-- -->
          <b-skeleton-table
            v-if="isLoadingFirstTime"
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true }"></b-skeleton-table>
          <b-table
           row-class="custom-row-class"
            v-else-if="items.length > 0"
            hover
            head-class="text-center"
            class="bg-table-heading bg-table-heading"
            @row-clicked="
              (e) => {
                $refs.leadInfoRef.getLeadInfo(e);
              }
            "
            :fields="fields"
            :items="items"
            show-empty>
            <template #head(checkbox)>
              <b-form-checkbox
                size="lg"
                v-model="selectAllLeads"
                @input="toggleSelectAllLeads()"
                class="checkbox"></b-form-checkbox>
            </template>
            <template #cell(checkbox)="data">
              <b-form-checkbox
                size="lg"
                v-model="selectedLeads"
                :value="data.item.id"
                class="checkbox"></b-form-checkbox>
            </template>
            <template #cell(letter)="data">
              <div
                class="text-uppercase d-flex align-items-center justify-content-center"
                style="height: 40px; display: block; width: 40px; color: white"
                :style="[{ background: randomColors[data.index] }]">
                {{ checkFirstCharacter(data.item) }}
              </div>
            </template>
            <template #cell(quiz)="data">
              {{ data.item.quiz_name }}
            </template>
            <template #cell(created_at)="data">
              {{ formattedDate(data.item.created_at)}}
             
            </template>
          </b-table>
             <template v-else>

<div class="d-flex justify-content-center align-items-center"  >

<EmptyTable >
<template #title>
No lead found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
          <b-pagination
            v-if="items.length"
            class="customPagination"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @input="PaginationData"></b-pagination>
        </div>
      </div>
    </div>
    <Transition name="fade">
      <div class="floatingDiv" v-if="selectedLeads.length">
        <div class="d-flex justify-content-center align-items-center">
          <div class="InnerDiv">
            <span class="pr-5 pl-2 mb-0"
              >Selected Leads: {{ selectedLeads.length }}</span
            >
            <div class="btn-group mr-2" role="group" aria-label="First group">
              <button v-b-modal.leadsBulkDelete class="btn deleteProduct">
                <!--  -->
                <span v-if="!deleteLeadLoading">
                  Delete <i class="fa fa-trash" aria-hidden="true"></i>
                </span>
                <div
                  v-else
                  class="spinner-border spinner-border-sm"
                  role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>

              <!-- Delete Modal -->
              <b-modal id="leadsBulkDelete" title="Delete Leads">
                <p class="my-4">
                  Are you sure you want to delete the selected leads? This can’t
                  be undone.
                </p>
                <template #modal-footer>
                  <div class="w-100 d-flex justify-content-end">
                    <b-button
                      :class="deleteLeadLoading ? 'disabled' : ''"
                      variant="danger"
                      @click="DeleteLeads()">
                      <span v-if="!deleteLeadLoading"> Confirm </span>
                      <div
                        v-else
                        class="spinner-border spinner-border-sm"
                        role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <LeadInformationModalVue ref="leadInfoRef" />
  </div>
</template>
<script>
import { debounce } from "lodash";
import XLSX from "xlsx";
import axios from "axios";
import LeadInformationModalVue from "./LeadInformationModal.vue";
import HeaderVue from '../../../Layout/Header.vue';
import EmptyTable from "../../../Layout/EmptyTable.vue";
// import Button from "../Layout/Button.vue";
export default {
  components: {
    HeaderVue,
    LeadInformationModalVue,
    EmptyTable
    // Button
  },
  watch: {
    "title"() {
      this.handleSearchDebounced();
    },
    allLeads({ selected, all }) {
      if (all.every((lead) => selected.includes(lead.id)) && all.length > 0)
        this.selectAllLeads = true;
      else this.selectAllLeads = false;
    },
  },
  computed: {
    segmentName(){
      const name=this.params.name
      return name?name:'Segment'
    },
    minDate() {
      var dateString = this.filter.start_date;
      var timestamp = Date.parse(dateString);
      return new Date(timestamp);
    },
    allLeads() {
      return { selected: this.selectedLeads, all: this.items };
    },
  },
  data() {
    return {
      
      selectAllLeads: false,
      title: "",
      searchQuiz: "",
      quizzes: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 20,
      randomColors: [],
      deleteLeadLoading: false,
      selectedLeads: [],
      isLoading: false,
      loadingFilter: false,
      isLoadingFirstTime: true,
      params:{},
      fields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "data-Table cursor-pointer align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "letter",
          label: "",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "full_name",
          label: "Name",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "email",
          label: "Email",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "quiz",
          label: "Quiz Name",
          tdClass: "data-Table align-middle",
          thClass: "bg-table-heading",
        },
        {
          key: "created_at",
          label: "Date",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
      ],
      items: [],
    };
  },
  methods: {
    toggleSelectAllLeads() {
      if (this.selectAllLeads)
        this.selectedLeads = [
          ...new Set([
            ...this.selectedLeads,
            ...this.items.map((tag) => tag.id),
          ]),
        ];
      else this.selectedLeads = [];
    },
    formattedDate(timestamp) {
      const date = new Date(timestamp);

      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = String(date.getUTCFullYear());

      return `${day}-${month}-${year}`;
    },

    
    exportToExcel() {
      const worksheet = XLSX.utils.json_to_sheet(this.items);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, "Leads.xlsx");
    },
    async DeleteLeads() {
      if (this.selectedLeads.length) {
        this.deleteLeadLoading = true;
        await axios
          .post(`/deleteUserLeads`, { ids: JSON.stringify(this.selectedLeads) })
          .then(() => {
            this.$toasted.show("Leads are successfully deleted", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
            this.selectedLeads = [];
            this.getLeads();
          })
          .catch((error) => {
            if (error) {
              this.$toasted.show("Error occured", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });
            }
            throw error;
          });
        this.deleteLeadLoading = false;
      }
    },
    async getLeads() {
      this.isLoading = true;
      await axios
        .get(`api/v1/segments/${this.params.id}/leads`, {params:{title:this.title,page:this.currentPage}})
        .then((response) => {
          if (response.data) {
            this.items = response.data.data;
            this.totalRows = response.data.data.total;
            this.generateRandomDarkColors();
          }
        })
        .catch((e) => console.log(e))
        .finally(() => (this.isLoading = false));
      this.isLoadingFirstTime = false;
    },
    checkFirstCharacter(item) {
      if (item.full_name) return item.full_name.charAt(0).toUpperCase();
      else if (item.email) return item.email.charAt(0).toUpperCase();
      return "Q";
    },
    async getFilter() {
      this.loadingFilter = true;
      axios
        .post("/filter", { page_name: "lead" })
        .then((response) => {
          this.quizzes = response.data.data.quizzes;
        })
        .catch((e) => console.log(e));
      this.loadingFilter = false;
    },
    generateRandomDarkColors() {
      this.randomColors = [];
      var letters = "0123456789ABCDEF";
      var numItems = this.items.length;

      for (var i = 0; i < numItems; i++) {
        var color = "#";

        for (var j = 0; j < 6; j++) {
          var randomIndex = Math.floor(Math.random() * letters.length);
          color += letters[randomIndex];
        }

        this.randomColors.push(color);
      }
    },
    PaginationData() {
      this.getLeads();
    },
    handleSearchDebounced: debounce(function () {
      this.getLeads();
    }, 500),
  },
  async created() {
    this.params=this.$route.params;
    if(this.$route.params.id)
    await this.getLeads();
    else this.isLoadingFirstTime=false;
   
  },
};
</script>

<style scoped>
.search-text:focus-visible {
  outline: none;
}
.search-text:active + .fa-search {
  color: #4d1b7e;
}
.tag {
  background: #bfbfc0;
  border-radius: 20px;
  border: 1px dashed;
  font-size: 14px;
}
.icon {
  color: #949495;
  transition: 0.5s ease all;
}
.icon:hover {
  background: rgb(77 27 126 / 20%);
  color: #4d1b7e;
}
.search {
  border-radius: 6px;
  border: 0.8px solid #b3afb6;
}
.search:focus-within {
  color: #495057;
  background-color: #fff;
  /* border-color: #4d1b7e; */
  outline: none;
  box-shadow: 0 0 0 0.1rem rgba(77, 27, 126, 0.25);
}
.cursor-pointer {
  cursor: pointer;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.floatingDiv {
  position: fixed;
  top: auto;
  bottom: 20px;
  width: 90%;
}
.::after {
  content: "";
  width: 100%;
  display: block;
  border-radius: 4px;
  height: 1px;
  border: 1px solid rgb(206 206 210 / 25%);
}
.floatingDiv .InnerDiv {
  z-index: 999 !important;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0rem 0.125rem 0.25rem #1f21241a, 0rem 0.0625rem 0.375rem #1f21240d,
    0rem 0.25rem 1.125rem -0.125rem #1f212414,
    0rem 0.75rem 1.125rem -0.125rem #1f212426;
  padding: 10px;
}
.deleteProduct {
  background: rgba(192, 21, 21, 0.2);
  border-radius: 6px;
  color: #c01515;
}
.download-btn {
  border-radius: 4px;
  background: rgba(77, 27, 126, 0.2);
}
.download-title {
  color: #1f1f28;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.loader {
  min-height: 40px;
}
.bg-table-heading bg-table-heading >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.bg-table-heading bg-table-heading >>> thead tr {
  border-radius: 10px;
  cursor:pointer;
}
.bg-table-heading bg-table-heading >>> thead th {
  border: none;
}
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
.dropdown >>> .btn-danger {
  color: #4d1b7e;
  background: rgba(77, 27, 126, 0.12);
  font-weight: 600;
  border: none;
}
.dropdown >>> .btn-danger:active {
  color: #3f1768;
  background: rgba(77, 27, 126, 0.12);
  font-weight: 600;
  border: none;
}
.date >>> #Starting,
.date >>> #ending {
  /* color: #3f1768; */
  color: #4d1b7e;
}
.collapse-title {
  font-size: 18px;
  font-weight: 500;
}
.custom-row-class:hover {
  cursor: pointer; /* Change this to the desired cursor style */
}

.collapsed > .collapse-title > .when-opened,
:not(.collapsed) > .collapse-title > .when-closed {
  display: none;
}

.btn-filter{
  border-radius: 24px;

color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
</style>
