<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="LeadInformationModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref="LeadInformationModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="container p-3">
            <div class="row mb-4">
              <div
                class="d-flex col-12 justify-content-between align-items-center">
                <h4 class="lead-info-heading">Lead information</h4>
                <button @click="closeModal" class="btn" style="">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <Skeleton v-if="isLoading" />
            <div class="" v-else>
              <!-- Lead Information -->
              <div class="row mb-2">
                <div class="col-12" >
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                     Quiz Name:
                      <span style="color: #4d4950; font-weight: 400">{{
                       quizTitle
                      }}</span>
                    </p>
                  </div>
                <div class="col-12" >
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Name:
                      <span style="color: #4d4950; font-weight: 400">{{
                        leadInfo.name
                      }}</span>
                    </p>
                  </div>
                <div class="col-12" >
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Email:
                      <span style="color: #4d4950; font-weight: 400">{{
                        leadInfo.email
                      }}</span>
                    </p>
                  </div>
                <div class="col-12" >
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Phone:
                      <span style="color: #4d4950; font-weight: 400">{{
                        leadInfo.phoneNumber
                      }}</span>
                    </p>
                  </div>
                <div class="col-12" >
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Date:
                      <span style="color: #4d4950; font-weight: 400">
                       {{ formattedDate }}
                     </span>
                    </p>
                  </div>
                <div class="col-12 d-flex align-items-center" >
                    <p
                      class="mr-2 "
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Result Key:
                     
                    </p>
                    <div class="mt-n3">
                    
                     <div class="w-100 d-flex align-items-center" style="flex:0.88" v-if="getQuizPreviewLink != ''">
                          <span>{{leadInfo.result_key }}</span>
                          <button class="btn" @click="openResultPreview"><i class="fa fa-link" aria-hidden="true"></i></button>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-12"
                    v-if="leadInfo.files && leadInfo.files.length"
                  >
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Files Uploaded:
                    </p>
                    <div
                      v-for="(file, Index) in leadInfo.files"
                      :key="Index"
                      class="d-flex align-items-center mb-2"
                    >
                      <i class="fa fa-caret-right mr-2" aria-hidden="true"></i>

                      <div class="d-flex align-items-center w-100">

                        <div class="d-flex " style="flex:0.8">
                           <p  class="ml-2 mb-0">File {{ Index + 1 }}</p>
                        </div>

                        <div class="d-flex" style="flex:0.2">

                          <button v-b-tooltip.hover title="Preview File" class="btn" @click="openFile(file)">
                            <b-icon-eye-fill></b-icon-eye-fill>
                          </button>
                         
                          <button class="btn" v-b-tooltip.hover title="Download File"  @click="downloadFile(file)">
                            <b-icon-cloud-download></b-icon-cloud-download>
                          </button>
                        </div>

                      </div>
                     
                    </div>
                  </div>

                  <div class="col-12" v-if="leadInfo.freeTextNote">
                    <div class="w-100" v-if="checkIsArrayOrString(leadInfo.freeTextNote) && checkIsArrayOrString(leadInfo.freeTextNote) == 'array'">

                       <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Free Text:
                     
                    </p>

                        <div
                      v-for="(freeText, Index) in  ParseArray(leadInfo.freeTextNote)"
                      :key="Index"
                      class="d-flex align-items-center mb-2"
                    >
                      <i class="fa fa-caret-right mr-2" aria-hidden="true"></i>

                      <div class="d-flex align-items-center w-100">

                        <div class="d-flex ">
                           <p  class="ml-2 mb-0">{{freeText.fieldValue}}</p>
                        </div>

                        

                      </div>
                     
                    </div>

                    </div>
                    <div class="w-100" v-else>
                      <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Free Text:
                      <span style="color: #4d4950; font-weight: 400">{{
                        leadInfo.freeTextNote
                      }}</span>
                    </p>
                    </div>
                    
                  </div>
                  <div class="col-12" v-if="leadInfo.total_score">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Total Quiz Score:
                      <span style="color: #4d4950; font-weight: 400">{{
                        leadInfo.total_score
                      }}</span>
                    </p>
                  </div>
                 
                  <div class="col-12" v-if="leadInfo.address1">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Address:
                      <span style="color: #4d4950; font-weight: 400">{{
                        leadInfo.address1
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="leadInfo.address2">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Address 2:
                      <span style="color: #4d4950; font-weight: 400">{{
                        leadInfo.address2
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="leadInfo.city">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      City:
                      <span style="color: #4d4950; font-weight: 400">{{
                        leadInfo.city
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="leadInfo.state">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      State:
                      <span style="color: #4d4950; font-weight: 400">{{
                        leadInfo.state
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="leadInfo.zip_code">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Zip Code:
                      <span style="color: #4d4950; font-weight: 400">{{
                        leadInfo.zip_code
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="leadInfo.country">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Country:
                      <span style="color: #4d4950; font-weight: 400">{{
                        leadInfo.country
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="leadInfo.organisation">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Organization:
                      <span style="color: #4d4950; font-weight: 400">{{
                        leadInfo.organisation
                      }}</span>
                    </p>
                  </div>
                  <div class="col-12" v-if="leadInfo.website">
                    <p
                      class=""
                      style="font-weight: 600; font-size: 16px; color: #4d4950"
                    >
                      Website:
                      <span style="color: #4d4950; font-weight: 400">{{
                        leadInfo.website
                      }}</span>
                    </p>
                  </div>
               
              </div>
              <!-- Questions -->
              <section class="py-2">
                <div class="row">
                  <div class="lead-info-heading col-12">Quiz Answers</div>
                </div>
                <!-- <div class="question-detail" v-if="checkArrayAndLength(leadInfo.questionAnswers)">
                 
                  <div
                    class="row font-weight-500 py-2"
                    v-for="(question,index) in leadInfo.questionAnswers"
                    :key="index">
                    <div class="col-12 col-md-6">{{ question.question }}</div>
                    <div class="col-12 col-md-6">
                      <span class="text-light-grey">Answer</span> {{ question.options[0].value }}
                    </div>
                  </div>
                </div>
                <div class="py-2" v-else style="font-size:16px">
                  Answers not provided for this quiz.
                </div> -->
                <div class="col-12">
                    <div
                    v-if="checkArrayAndLength(leadInfo.questionAnswers)"
                      class="py-2"
                      style="
                        min-height: 50px;
                        height: 100%;
                        max-height: 250px;
                        overflow: hidden scroll;
                      "
                    >
                      <div
                        class="mb-3"
                        v-for="(question, index) in leadInfo.questionAnswers"
                        :key="index"
                      >
                        <div class="d-flex align-items-center">
                          <div class="d-flex align-items-center " style="flex:0.4">
                            <span
                              style="
                                font-size: 16px;
                                color: #292929;
                                font-weight: 400;
                              "
                              class="mr-3"
                              >Q.{{ index + 1 }}</span
                            >
                            <div class="">
                              <p
                                class="m-0"
                                style="
                                  font-size: 17px;
                                  color: #292929;
                                  font-weight: 400;
                                "
                              >
                                {{ question.question }}
                              </p>
                            </div>
                          </div>

                          <div class="mt-2 pl-4" style="flex:0.6">
                            <div
                              v-for="(ans, index2) in question.options"
                              :key="index2"
                              class="d-flex align-items-center mb-2"
                            >
                              <i
                                class="fa fa-caret-right mr-2"
                                aria-hidden="true"
                              ></i>

                              <div>
                                <div v-if="ans.image">
                                  <LazyImage
                                    :src="ImageSRC(ans.image)"
                                    alt=""
                                    class="img-thumbnail"
                                    style="
                                      height: 50px;
                                      min-height:50px;
                                      min-width:50px;
                                      width: 50px;
                                      border-radius: 50%;
                                      object-fit: cover;
                                    "
                                  />
                                </div>
                              </div>
                              <p v-if="ans.value" class="ml-2 mb-0">
                                {{ ans.value }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="py-2" v-else style="font-size:16px">
                  Answers not provided for this quiz.
                </div>
                  </div>
              </section>
              <section class="py-2">
                <div class="row">
                  <div class="lead-info-heading col-12 py-2">
                    Suggested Products
                  </div>
                </div>
                <div class="product-detail" v-if="checkArrayAndLength(leadInfo.products)">
                  <div
                    class="d-flex align-items-center justify-content-center px-2"
                    style="width: fit-content">
                    <div class="px-3" :title="title" v-for="({image,title},index) in leadInfo.products" :key="index">
                      <lazy-image
                        class="product-image"
                        :src="image"
                        key="image">
                      </lazy-image>
                      <div class="product-title"  style="max-width:120px; text-overflow: ellipsis; white-space:nowrap; overflow: hidden;"> {{ title }}</div>
                    </div>
                  </div>
                </div>
                 <div class="py-2" v-else style="font-size:16px">
                  Products not available for this quiz.
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import Skeleton from "./LeadInformationSkeleton.vue";
export default {
  props: {},

  data() {
    return {
      isLoading: false,
      leadInfo:{},
      quizTitle:null,
      quizCreatedDate:null,
      baseURL:process.env.VUE_APP_API_BASE_URL
    };
  },
  watch: {},
computed:{
  getQuizPreviewLink(){
    return this.leadInfo.result_key ? `${window.location.origin}/preview/${this.leadInfo.quiz_key}?result_key=${this.leadInfo.result_key}` : null
  },
 formattedDate() {
  const timestamp= this.quizCreatedDate
      const date = new Date(timestamp);

      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = String(date.getUTCFullYear());

      return `${day}-${month}-${year}`;
    },
},
  methods: {
    openResultPreview(){
      if(this.getQuizPreviewLink){
        window.open(this.getQuizPreviewLink,"_blank")
      }
    },
checkArrayAndLength(data){
    if(Array.isArray(data))
    if(data.length>0)
    return true
    return false
},
checkIsArrayOrString(value){
       let parsedValue;
       if (typeof value === 'string') {
    try {
      parsedValue = JSON.parse(value);
      if (Array.isArray(parsedValue)) {
        return 'array';
      }
    } catch (error) {
      // It is a normal string
      return 'string';
    }
  }

  return null; 
    },
    ParseArray(arr){
       let parsedArray;
  
  try {
    parsedArray = JSON.parse(arr);
  } catch (error) {
    parsedArray = arr;
  }
  
  return parsedArray;
    },
openFile(file){
      if(file){
        window.open(file.path,"_blank")
      }
    },
    ImageSRC(image) {
      if (image.indexOf("http://") === 0 || image.indexOf("https://") === 0) {
        return image;
      } else {
        let dbImage;
        if (image.startsWith("/")) {
          dbImage = image.substring(1);
        } else {
          dbImage = image;
        }
        const baseURL = process.env.VUE_APP_API_BASE_URL;
        // return baseURL + image
        return baseURL + dbImage;
      }
    },
   async downloadFile(file){
      if(file){
         try {
           this.$toasted.show("File preparing to download", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        const response = await fetch(file.path);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'download-file';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } catch (error) {
       
       this.$toasted.show("Error occured while downloading file.", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        throw(error)
      }
      }
    },
    OpenModal() {
      $("#LeadInformationModal").modal("show");
    },
    closeModal() {
      $("#LeadInformationModal").modal("hide");
    },
   async getLeadInfo(lead){
    this.quizTitle=lead.quiz.title;
    this.quizCreatedDate=lead.created_at;
    
      this.isLoading=true;
       this.OpenModal();
    await  axios.get('/getLeadResultHistory/'+lead.id).then(response=>{
     this.leadInfo = response.data.data

      }).catch(()=>{
         this.$toasted.show("Error while loading lead info", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
      })
      this.isLoading=false;
    },
  },
  components: { Skeleton },
};
</script>
<style>
.product-image {
  height: 120px;
  width: 120px;
  border-radius: 12px;
  border: 0.5px solid var(--e-0-dee-2, #e0dee2);
}
.product-detail {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.product-detail::-webkit-scrollbar-thumb {
  background: Light Gray;
  border-radius: 5px;
  height: 10px;
  scrollbar-width: thin;
}

.product-detail::-webkit-scrollbar-track {
  background: transparent;
  height: 10px;
  scrollbar-width: thin;
}

.question-detail {
  height: 134px;
  overflow-y: auto;
  overflow-x: hidden;
}
.pointer-event-none {
  pointer-events: none;
}
.text-light-grey {
  color: #73738d;
}
</style>
<style scoped>
.lead-info-heading {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.72px;
}
.font-weight-500 {
  font-weight: 500;
}
</style>
